import "./style.scss";

export default function Modal({ height = "100%" }) {
    return (
        <div className="modal" style={{ height: `${height}` }}>
            <div className="modal-window">
                <h2>Дякуємо, ви успішно зареєструвались на курс!</h2>
                <p>На вашу електронну адресу було відправлено лист з підтвердженням реєстрації.</p>
                <p>Тепер ви можете переходити до наступного уроку!</p>
            </div>
        </div>
    );
}
