import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// hooks
import useIsMount from "../../../hooks/useIsMount";

// api
import axios from "axios";
import API from "../../../app/api";

// components
import CustomSelect from "../../../components/UI/CustomSelect/CustomSelect";
import Loader from "../../../components/Loader/Loader";
import SelectWithSearch from "../../../components/UI/Nush/SelectWithSearch/SelectWithSearch";
import Input from "../../../components/UI/Nush/Input/Input";
import RadioButtonGroup from "../../../components/UI/RadioButtonGroup/RadioButtonGroup";
import CustomCheckBox from "../../../components/UI/CustomCheckBox/CustomCheckBox";
import CustomInputWithoutValidation from "../../../components/UI/CustomInputWithoutValidation/CustomInputWithoutValidation";
import Modal from "../../../components/nush-applications/Modal/Modal";

// styles
import "./style.scss";

// regular expressions
const numberRegExp = new RegExp(/^\d+$/);
const nameRegExp = new RegExp(/^[АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЬьЮюЯя'"`-]+$/, "g");
const emailRegExp = new RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
    "i"
);

const settlementNotFoundOption = "Мого населеного пункту немає в списку";
const institutionNotFoundOption = "Мого закладу післядипломної педагогічної освіти немає в списку";

// list options
const TOTAL_EXPERIENCE_OPTIONS = ["До 3-х років", "Від 3-х до 10 років", "Від 11 до 20 років", "Понад 20 років"];
const TOTAL_PEDAGOGICAL_EXPERIENCE_OPTIONS = ["До 3-х років", "Від 3-х до 10 років", "Від 11 до 20 років", "Понад 20 років"];
const TOTAL_COACHING_EXPERIENCE_OPTIONS = [
    "До 3-х років",
    "Від 3-х до 10 років",
    "Від 11 до 20 років",
    "Понад 20 років",
    "Не маю досвіду тренера в ЗППО",
];
const COACHING_EXPERIENCE_CURRENT_OPTIONS = [
    "До 3-х років",
    "Від 3-х до 10 років",
    "Від 11 до 20 років",
    "Понад 20 років",
    "Не маю досвіду тренера в ЗППО",
];
const TEACHING_EXPERIENCE_OPTIONS = [
    "До 3-х років",
    "Від 3-х до 10 років",
    "Від 11 до 20 років",
    "Понад 20 років",
    "Не маю досвіду викладання в якості вчителя",
];
const EDUCATIONAL_INDUSTRY = [
    "Мовно-літературна",
    "Іншомовна",
    "Математична",
    "Природнича",
    "Технологіча",
    "Інформатична",
    "Соціальна і здоров’язбережувальна",
    "Громадянська та історична",
    "Мистецька",
    "Фізкультурна",
];
const GENDER_OPTIONS = ["Жінка", "Чоловік", "Не бажаю вказувати"];
const EVENTS_PARTICIPATION_OPTIONS = ["Так", "Ні, беру участь вперше"];

const NushCoursesFormPage = () => {
    const navigate = useNavigate();
    const isMount = useIsMount();

    const [isFormSent, setIsFormSent] = useState(false);
    const [course, setCourse] = useState("Не вказано");

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const courseParam = urlParams.get("courseTitle");
        if (courseParam) {
            setCourse(courseParam.substring(0, 200));
        }
        const submittedParam = urlParams.get("submitted");
        if (submittedParam === "true") {
            setIsFormSent(true);
        }

        console.log("course:", courseParam, "submited:", submittedParam);
    }, []);

    const sendHeight = () => {
        const height = document.body.scrollHeight;
        window.parent.postMessage({ event: "set-height", height }, "*");
        console.log(height);
    };

    useEffect(() => {
        sendHeight();
        window.addEventListener("resize", sendHeight);

        return () => {
            window.removeEventListener("resize", sendHeight);
        };
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [firstNameValidation, setFirstNameValidation] = useState(true);
    const [firstNameValidationMsg, setFirstNameValidationMsg] = useState(undefined);

    const [lastName, setLastName] = useState("");
    const [lastNameValidation, setLastNameValidation] = useState(true);
    const [lastNameValidationMsg, setLastNameValidationMsg] = useState(undefined);

    const [email, setEmail] = useState("");
    const [emailValidation, setEmailValidation] = useState(true);
    const [emailValidationMsg, setEmailValidationMsg] = useState(undefined);

    const [phone, setPhone] = useState("");
    const [phoneValidation, setPhoneValidation] = useState(true);
    const [phoneValidationMsg, setPhoneValidationMsg] = useState(undefined);

    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState(undefined);

    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState(undefined);

    const [communities, setCommunities] = useState([]);
    const [community, setCommunity] = useState(undefined);

    const [settlements, setSettlements] = useState([]);
    const [settlement, setSettlement] = useState(undefined);
    const [userSettlement, setUserSettlement] = useState("");

    const [institutions, setInstitutions] = useState([]);
    const [institution, setInstitution] = useState("");
    const [institutionSearch, setInstitutionSearch] = useState("");
    const [institutionOptions, setInstitutionOptions] = useState([]);
    const [userInstitution, setUserInstitution] = useState("");

    const [totalExperience, setTotalExperience] = useState(undefined);
    const [totalPedagogicalExperience, setTotalPedagogicalExperience] = useState(undefined);
    const [totalCoachingExperience, setTotalCoachingExperience] = useState(undefined);
    const [coachingExperienceCurrent, setCoachingExperienceCurrent] = useState(undefined);
    const [teachingExperience, setTeachingExperience] = useState(undefined);
    const [educationalIndustry, setEducationalIndustry] = useState(undefined);
    const [eventParticipation, setEventParticipation] = useState(undefined);

    const [age, setAge] = useState("");
    const [ageValidation, setAgeValidation] = useState(true);
    const [ageValidationMsg, setAgeValidationMsg] = useState(undefined);

    const [gender, setGender] = useState(undefined);

    const [idp, setIdp] = useState("Ні");
    const [veteranStatus, setVeteranStatus] = useState("Ні");

    const [disablement, setDisablement] = useState("Ні");

    const [isReportingAllowed, setIsReportingAllowed] = useState(true);

    const [isCommunicationAllowed, setIsCommunicationAllowed] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(API.institution.readAll)
            .then((response) => {
                const { data } = response;
                setInstitutions(
                    data.map((institution) => {
                        institution.value = institution.title;
                        return institution;
                    })
                );
                setIsLoading(false);
            })
            .catch((error) => {
                navigate("/error", { state: { error: error.response.data.message } });
            });
    }, [navigate]);

    useEffect(() => {
        setIsLoading(true);
        axios
            .post(API.locations.regionsRead)
            .then((response) => {
                let { data } = response;
                setRegions(
                    data
                        .map((region) => region.region)
                        .sort((a, b) => {
                            return a.localeCompare(b);
                        })
                );
                setIsLoading(false);
            })
            .catch((error) => {
                navigate("/error", { state: { error: error.response.data.message } });
            });
    }, []);

    useEffect(() => {
        if (region) {
            setIsLoading(true);
            axios
                .post(API.locations.districtsRead, {
                    region: region,
                })
                .then((response) => {
                    let { data } = response;
                    setDistricts(data.map((district) => district.district));
                    setIsLoading(false);
                })
                .catch((error) => {
                    navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    }, [region]);

    useEffect(() => {
        if (region && district) {
            setIsLoading(true);
            axios
                .post(API.locations.communitiesRead, {
                    region: region,
                    district: district,
                })
                .then((response) => {
                    let { data } = response;
                    setCommunities(data.map((community) => community.community));
                    setIsLoading(false);
                })
                .catch((error) => {
                    navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    }, [region, district]);

    useEffect(() => {
        if (region && district && community) {
            setIsLoading(true);
            axios
                .post(API.locations.settlementsRead, {
                    region: region,
                    district: district,
                    community: community,
                })
                .then((response) => {
                    let { data } = response;
                    setSettlements([...data.map((settlement) => settlement.settlement), settlementNotFoundOption]);
                    setIsLoading(false);
                })
                .catch((error) => {
                    navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    }, [region, district, community]);

    const clearDistrict = () => {
        setDistrict(undefined);
        setDistricts([]);
    };

    const clearCommunity = () => {
        setCommunity(undefined);
        setCommunities([]);
    };

    const clearSettlement = () => {
        setSettlement(undefined);
        setSettlements([]);
    };

    const nameValidation = (setValidation, setValidationMsg, field, value) => {
        const nameRegExp = new RegExp(/^[АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЬьЮюЯя'"`-]+$/, "g");
        if (value.length < 2) {
            setValidation(false);
            return setValidationMsg(field + " повинно мати як мінімум дві літери.");
        } else if (!nameRegExp.test(value)) {
            setValidation(false);
            return setValidationMsg(field + " повинно мати тільки українські літери та не містити пробілів.");
        } else {
            setValidation(true);
            return setValidationMsg(undefined);
        }
    };

    const firstNameHandler = (e) => setFirstName(e.target.value.substring(0, 200));
    useEffect(() => {
        if (isMount) return;
        return nameValidation(setFirstNameValidation, setFirstNameValidationMsg, "Iм'я", firstName);
    }, [firstName]);

    const lastNameHandler = (e) => setLastName(e.target.value.substring(0, 200));
    useEffect(() => {
        if (isMount) return;
        return nameValidation(setLastNameValidation, setLastNameValidationMsg, "Прізвище", lastName);
    }, [lastName]);

    const emailHandler = (e) => setEmail(e.target.value.substring(0, 200));
    useEffect(() => {
        if (isMount) return;
        if (!emailRegExp.test(email)) {
            setEmailValidation(false);
            setEmailValidationMsg("Вказано некоректну електронну адресу.");
        } else {
            setEmailValidation(true);
            setEmailValidationMsg(undefined);
        }
    }, [email]);

    const phoneHandler = (e) => setPhone(e.target.value.substring(0, 200));
    useEffect(() => {
        if (isMount) return;
        if (!numberRegExp.test(phone)) {
            setPhoneValidation(false);
            setPhoneValidationMsg("Телефон повинен містити тільки цифри.");
        } else if (phone.length !== 9) {
            setPhoneValidation(false);
            setPhoneValidationMsg("Телефон повинен містити 9 цифр.");
        } else {
            setPhoneValidation(true);
            setPhoneValidationMsg(undefined);
        }
    }, [phone]);

    const regionHandler = (option = undefined) => {
        if (option === undefined) {
            setRegion(undefined);
        } else {
            setRegion(option);
        }
        clearDistrict();
        clearCommunity();
        clearSettlement();
    };

    const districtHandler = (option = undefined) => {
        if (option === undefined) {
            setDistrict(undefined);
        } else {
            setDistrict(option);
        }
        clearCommunity();
        clearSettlement();
    };

    const communityHandler = (option = undefined) => {
        if (option === undefined) {
            setCommunity(undefined);
        } else {
            setCommunity(option);
        }
        clearSettlement();
    };

    const settlementHandler = (option = undefined) => {
        if (option === undefined) return setSettlement(undefined);
        setUserSettlement("");
        return setSettlement(option);
    };

    const userSettlementHandler = (e) => setUserSettlement(e.target.value.substring(0, 200));

    const institutionHandler = (i) => {
        const value = i.value;
        if (value === "undefined") {
            setInstitution(undefined);
            return;
        } else {
            setInstitution(value);
            setInstitutionSearch(value);
            setInstitutionOptions([value]);
            setUserInstitution("");
        }
    };

    const institutionFocusHandler = () => {
        setInstitutionSearch("");
        setInstitutionOptions([...institutions, { value: institutionNotFoundOption }]);
    };

    const institutionBlurHandler = () => {
        setInstitutionSearch(institution);
    };

    const institutionSearchHandler = (e) => {
        let value = e.target.value;
        setInstitutionSearch(value);
        let searchQuery = value.toLowerCase();
        let results = institutions.filter((i) => {
            return i.value.toLowerCase().includes(searchQuery);
        });

        if (results.length !== 0) {
            setInstitutionOptions([...results, { value: institutionNotFoundOption }]);
        } else {
            setInstitutionOptions([{ value: institutionNotFoundOption }]);
        }
    };
    const userInstitutionHandler = (e) => setUserInstitution(e.target.value.substring(0, 200));

    const totalExperienceHandler = (value) => setTotalExperience(value);
    const totalPedagogicalExperienceHandler = (value) => setTotalPedagogicalExperience(value);
    const totalCoachingExperienceHandler = (value) => setTotalCoachingExperience(value);
    const coachingExperienceCurrentHandler = (value) => setCoachingExperienceCurrent(value);
    const teachingExperienceHandler = (value) => setTeachingExperience(value);
    const educationalIndustryHandler = (value) => setEducationalIndustry(value);
    const eventParticipationHandler = (value) => setEventParticipation(value);
    const genderHandler = (value) => setGender(value);
    const idpHandler = (e) => setIdp(e.target.value);
    const veteranStatusHandler = (e) => setVeteranStatus(e.target.value);
    const disablementHandler = (e) => setDisablement(e.target.value);
    const isReportingAllowedHandler = () => setIsReportingAllowed((prev) => !prev);
    const isCommunicationAllowedHandler = () => setIsCommunicationAllowed((prev) => !prev);

    const ageHandler = (e) => {
        const value = e.target.value.substring(0, 200);
        if (numberRegExp.test(value) || value === "") {
            return setAge(value);
        }

        return;
    };
    useEffect(() => {
        if (isMount) return;
        if (!numberRegExp.test(age)) {
            setAgeValidation(false);
            setAgeValidationMsg("Поле повинно містити ціле ціле невід'ємне число.");
        } else if (age.length > 2) {
            setAgeValidation(false);
            setAgeValidationMsg("Занадто велике число.");
        } else if (age < 18) {
            setAgeValidation(false);
            setAgeValidationMsg("Вам повинно бути 18 років.");
        } else {
            setAgeValidation(true);
            setAgeValidationMsg(undefined);
        }
    }, [age]);

    useEffect(() => {
        sendHeight();
        if (!firstName || firstNameValidationMsg) return setIsFormValid(false);
        if (!lastName || lastNameValidationMsg) return setIsFormValid(false);
        if (!email || emailValidationMsg) return setIsFormValid(false);
        if (!phone || phoneValidationMsg) return setIsFormValid(false);
        if (!region) return setIsFormValid(false);
        if (!district) return setIsFormValid(false);
        if (!community) return setIsFormValid(false);
        if (!settlement || (settlement === settlementNotFoundOption && !userSettlement)) return setIsFormValid(false);
        if (!institution || (institution === institutionNotFoundOption && !userInstitution)) return setIsFormValid(false);
        if (!totalExperience) return setIsFormValid(false);
        if (!totalPedagogicalExperience) return setIsFormValid(false);
        if (!totalCoachingExperience) return setIsFormValid(false);
        if (!coachingExperienceCurrent) return setIsFormValid(false);
        if (!teachingExperience) return setIsFormValid(false);
        if (!educationalIndustry) return setIsFormValid(false);
        if (!eventParticipation) return setIsFormValid(false);
        if (!gender) return setIsFormValid(false);
        if (!age || ageValidationMsg) return setIsFormValid(false);
        if (!isReportingAllowed) return setIsFormValid(false);
        return setIsFormValid(true);
    }, [
        firstName,
        firstNameValidationMsg,
        lastName,
        lastNameValidationMsg,
        email,
        emailValidationMsg,
        phone,
        phoneValidationMsg,
        region,
        district,
        community,
        settlement,
        userSettlement,
        institution,
        userInstitution,
        totalExperience,
        totalPedagogicalExperience,
        totalCoachingExperience,
        coachingExperienceCurrent,
        teachingExperience,
        educationalIndustry,
        eventParticipation,
        gender,
        age,
        ageValidationMsg,
        isReportingAllowed,
    ]);

    const sendForm = () => {
        if (isFormValid) {
            axios
                .post(API.application.nush.nushApplicationsCreate, {
                    course,
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    phone: `+380${phone}`,
                    region,
                    district,
                    community,
                    settlement: userSettlement || settlement,
                    institution: userInstitution || institution,
                    total_experience: totalExperience,
                    total_pedagogical_experience: totalPedagogicalExperience,
                    total_coaching_experience: totalCoachingExperience,
                    coaching_experience_current: coachingExperienceCurrent,
                    teaching_experience: teachingExperience,
                    educational_industry: educationalIndustry,
                    event_participation: eventParticipation,
                    gender,
                    age,
                    idp,
                    veteran_status: veteranStatus,
                    disablement,
                    communication: isCommunicationAllowed,
                })
                .then((response) => {
                    setIsFormSent(true);
                    sendHeight();
                    window.parent.postMessage(
                        {
                            event: "submit",
                        },
                        "*"
                    );
                })
                .catch((error) => {
                    console.log(error);
                    // navigate("/error", { state: { error: error.response.data.message } });
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Реєстрація на курс НУШ</title>
            </Helmet>

            <div className="container">
                <main className="nush-form-page" data-form-sent={isFormSent}>
                    <div className="nush-form-page-alert">
                        <div className="nush-form-page-alert-title">
                            <div>!</div>
                            <h2>Повідомлення про збір та обробку даних</h2>
                        </div>
                        <div className="nush-form-page-alert-content">
                            Просимо вас надавати коректну та актуальну інформацію при заповненні форми. Ця інформація допоможе нам
                            проаналізувати стан освіти в України з урахуванням регіональних та гендерних аспектів. Також дані будуть
                            узагальнено використані для звітування у межах комунікації про проєкт. Обробка даних складається зі знеособлених
                            даних, тож не несе ризику порушення прав і свобод пов'язаних із приватністю. Ми не передаватимемо ваші контактні
                            дані третім сторонам.
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <Input
                                label="Ім’я"
                                value={firstName}
                                handler={firstNameHandler}
                                validation={firstNameValidation}
                                validationMsg={firstNameValidationMsg}
                                required={true}
                            />
                        </div>
                        <div className="form-col-2">
                            <Input
                                label="Прізвище"
                                value={lastName}
                                handler={lastNameHandler}
                                validation={lastNameValidation}
                                validationMsg={lastNameValidationMsg}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <Input
                                label="Електронна пошта"
                                value={email}
                                handler={emailHandler}
                                validation={emailValidation}
                                validationMsg={emailValidationMsg}
                                required={true}
                            />
                        </div>
                        <div className="form-col-2">
                            <Input
                                label="Номер телефону"
                                value={phone}
                                handler={phoneHandler}
                                validation={phoneValidation}
                                validationMsg={phoneValidationMsg}
                                required={true}
                                type="phone"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect
                                label="Область"
                                value={region}
                                options={regions}
                                handler={regionHandler}
                                required={true}
                                disabled={false}
                            />
                        </div>

                        <div className="form-col-2">
                            <CustomSelect
                                label="Район"
                                value={district}
                                options={districts}
                                handler={districtHandler}
                                required={true}
                                disabled={region ? false : true}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect
                                label="Громада"
                                value={community}
                                options={communities}
                                handler={communityHandler}
                                required={true}
                                disabled={region && district ? false : true}
                            />
                        </div>

                        <div className="form-col-2">
                            <CustomSelect
                                label="Населений пункт"
                                value={settlement}
                                options={settlements}
                                handler={settlementHandler}
                                required={true}
                                disabled={region && district && community ? false : true}
                            />
                        </div>
                    </div>

                    {settlement === settlementNotFoundOption && (
                        <div className="form-row">
                            <div className="form-col-2">
                                <CustomInputWithoutValidation
                                    label="Уведіть назву населеного пункту вручну"
                                    type="text"
                                    required={true}
                                    value={userSettlement}
                                    handler={userSettlementHandler}
                                />
                            </div>
                        </div>
                    )}

                    <SelectWithSearch
                        label="Назва регіонального закладу післядипломної педагогічної освіти (де працюєте, чи до якого будете прикріплені після навчання)"
                        value={institution}
                        search={institutionSearch}
                        searchHandler={institutionSearchHandler}
                        options={institutionOptions}
                        optionsHandler={institutionHandler}
                        focusHandler={institutionFocusHandler}
                        blurHandler={institutionBlurHandler}
                        required={true}
                    />

                    {institution === institutionNotFoundOption && (
                        <div className="form-row">
                            <div className="form-col-2">
                                <CustomInputWithoutValidation
                                    label="Уведіть назву регіонального закладу післядипломної педагогічної освіти вручну"
                                    type="text"
                                    required={true}
                                    value={userInstitution}
                                    handler={userInstitutionHandler}
                                />
                            </div>
                        </div>
                    )}

                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect
                                label="Ваш загальний стаж"
                                value={totalExperience}
                                options={TOTAL_EXPERIENCE_OPTIONS}
                                handler={totalExperienceHandler}
                                required={true}
                            />
                        </div>
                        <div className="form-col-2">
                            <CustomSelect
                                label="Ваш загальний педагогічний стаж"
                                value={totalPedagogicalExperience}
                                options={TOTAL_PEDAGOGICAL_EXPERIENCE_OPTIONS}
                                handler={totalPedagogicalExperienceHandler}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect
                                label="Ваш загальний досвід викладання в якості тренера ЗППО"
                                value={totalCoachingExperience}
                                options={TOTAL_COACHING_EXPERIENCE_OPTIONS}
                                handler={totalCoachingExperienceHandler}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect
                                label="Ваш досвід викладання в якості тренера ЗППО у поточному навчальному закладі"
                                value={coachingExperienceCurrent}
                                options={COACHING_EXPERIENCE_CURRENT_OPTIONS}
                                handler={coachingExperienceCurrentHandler}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect
                                label="Ваш досвід викладання в якості вчителя (якщо є)"
                                value={teachingExperience}
                                options={TEACHING_EXPERIENCE_OPTIONS}
                                handler={teachingExperienceHandler}
                                required={true}
                            />
                        </div>
                        <div className="form-col-2">
                            <CustomSelect
                                label="Яку освітню галузь ви представляєте?"
                                value={educationalIndustry}
                                options={EDUCATIONAL_INDUSTRY}
                                handler={educationalIndustryHandler}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect
                                label="Чи брали ви раніше участь у будь-яких навчальних програмах/подіях від Освіторії?"
                                value={eventParticipation}
                                options={EVENTS_PARTICIPATION_OPTIONS}
                                handler={eventParticipationHandler}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <CustomSelect label="Стать" value={gender} options={GENDER_OPTIONS} handler={genderHandler} required={true} />
                        </div>
                        <div className="form-col-2">
                            <Input
                                label="Вік"
                                value={age}
                                handler={ageHandler}
                                validation={ageValidation}
                                validationMsg={ageValidationMsg}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <RadioButtonGroup
                                title="Чи є у вас статус внутрішньо переміщеної особи?"
                                name="idp"
                                options={["Ні", "Так"]}
                                value={idp}
                                handler={idpHandler}
                                required={true}
                            />
                        </div>
                        <div className="form-col-2">
                            <RadioButtonGroup
                                title="Чи маєте ви статус ветерана війни?"
                                name="veteran-status"
                                options={["Ні", "Так"]}
                                value={veteranStatus}
                                handler={veteranStatusHandler}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col-2">
                            <RadioButtonGroup
                                title="Чи є у вас статус особи з особливими потребами?"
                                name="disablement"
                                options={["Ні", "Так"]}
                                value={disablement}
                                handler={disablementHandler}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <CustomCheckBox
                            label={
                                "Я погоджуюся на використання моїх даних для узагальненої звітності та комунікації щодо результатів тренінгу у межах проєкту."
                            }
                            value={isReportingAllowed}
                            handler={isReportingAllowedHandler}
                            required={true}
                        />
                    </div>

                    <div className="form-row">
                        <CustomCheckBox
                            label="Я погоджуюся отримувати email та телефонні розсилки з анонсами освітніх подій, тренінгів, курсів тощо."
                            value={isCommunicationAllowed}
                            handler={isCommunicationAllowedHandler}
                        />
                    </div>

                    <div className="nush-form-page-button-validations">
                        <div>{!firstName && "Вам потрібно вказати ім’я."}</div>
                        <div>{firstNameValidationMsg}</div>

                        <div>{!lastName && "Вам потрібно вказати прізвище."}</div>
                        <div>{lastNameValidationMsg}</div>

                        <div>{!email && "Вам потрібно вказати електронну адресу."}</div>
                        <div>{emailValidationMsg}</div>

                        <div>{!phone && "Вам потрібно вказати номер телефону."}</div>
                        <div>{phoneValidationMsg}</div>

                        <div>{!region && "Вам потрібно вказати область."}</div>
                        <div>{!district && "Вам потрібно вказати район."}</div>
                        <div>{!community && "Вам потрібно вказати громаду."}</div>

                        <div>
                            {(!settlement || (settlement === settlementNotFoundOption && !userSettlement)) &&
                                "Вам потрібно вказати населений пункт."}
                        </div>

                        <div>
                            {(!institution || (institution === institutionNotFoundOption && !userInstitution)) &&
                                "Вам потрібно вказати заклад післядипломної педагогічної освіти."}
                        </div>

                        <div>{!totalExperience && "Вам потрібно вказати загальний стаж."}</div>
                        <div>{!totalPedagogicalExperience && "Вам потрібно вказати загальний педагогічний стаж."}</div>
                        <div>{!totalCoachingExperience && "Вам потрібно вказати загальний досвід викладання в якості тренера ЗППО."}</div>

                        <div>
                            {!coachingExperienceCurrent &&
                                "Вам потрібно вказати досвід викладання в якості тренера ЗППО у поточному навчальному закладі."}
                        </div>

                        <div>{!teachingExperience && "Вам потрібно вказати досвід викладання в якості вчителя."}</div>
                        <div>{!educationalIndustry && "Вам потрібно вказати освітню галузь ви представляєте."}</div>

                        <div>
                            {!eventParticipation &&
                                "Вам потрібно вказати чи брали ви раніше участь у будь-яких навчальних програмах/подіях від Освіторії."}
                        </div>

                        <div>{!gender && "Вам потрібно вказати стать."}</div>

                        <div>{!age && "Вам потрібно вказати вік."}</div>
                        <div>{ageValidationMsg}</div>

                        <div>{!isReportingAllowed && "Згода на використання даних обов’язкова."}</div>
                    </div>

                    <div className="nush-form-page-button">
                        <button onClick={sendForm} className="submit-button" disabled={!isFormValid}>
                            Надіслати
                        </button>
                    </div>

                    <div className="support">
                        Виникли питання чи труднощі під час заповнення форми? Напишіть лист на електронну адресу{" "}
                        <a href="mailto:masterclass@osvitoria.org" target="_blank" rel="noreferrer">
                            masterclass@osvitoria.org
                        </a>
                        , і ми обов'язково допоможемо її вирішити.
                    </div>
                </main>
            </div>
            {isLoading && <Loader />}
            {isFormSent && <Modal height="400px" />}
        </>
    );
};

export default NushCoursesFormPage;
